import {
  SET_IS_AUTHENTICATED,
  SET_USER_INFO,
  SET_DEPENDENTS,
  SET_ACCOUNT_SUMMARY,
  SET_ACTIVITIES,
  SET_SSO,
  SET_UPCOMING_PAYMENT,
  SET_LOADING,
  SET_HOME_GYM,
  SET_ADDRESS,
  SET_MOBILENAVBARVISIBLE,
  LOG_OUT,
  SET_HOME_GYM_LIST,
  SET_EMAIL,
  SET_ENROLLED_DEPENDENTS,
  SET_ADDRESS_ERROR
} from '../reducers/user';
import axios from 'axios';
import {
  checkEligibility,
  hasEligibility
} from '../../utilities/checkEligibility';
import { isUnderage } from '../../utilities/checkIfUnderage';
import { isPast } from 'date-fns';
import { isFuture } from 'date-fns/esm';

export const setDependents = dependents => ({
  type: SET_DEPENDENTS,
  dependents
});
export const setEnrolledDependents = enrolledDependents => ({
  type: SET_ENROLLED_DEPENDENTS,
  enrolledDependents
});

export const setAccountSummary = accountSummary => ({
  type: SET_ACCOUNT_SUMMARY,
  accountSummary
});

export const setEmail = email => ({
  type: SET_EMAIL,
  email
});

export const setActivities = activities => ({
  type: SET_ACTIVITIES,
  activities
});

export const setSSO = sso => ({
  type: SET_SSO,
  sso
});

export const setUpcomingPayment = nextPaymentInfo => ({
  type: SET_UPCOMING_PAYMENT,
  nextPaymentInfo
});

export const setIsAuthenticated = isAuthenticated => ({
  type: SET_IS_AUTHENTICATED,
  isAuthenticated
});

export const setHomeGym = homeGymCode => ({
  type: SET_HOME_GYM,
  homeGymCode
});

export const setHomeGymList = homeGymList => ({
  type: SET_HOME_GYM_LIST,
  homeGymList
});

export const setLoading = (loadingType, isLoading) => ({
  type: SET_LOADING,
  loadingType,
  isLoading
});

export const setAddress = (addressType, address) => ({
  type: SET_ADDRESS,
  addressType,
  address
});

export const setAddressError = (addressType, error) => ({
  type: SET_ADDRESS_ERROR,
  addressType,
  error
});

const getDependents = async (programCode, dependents, client) => {
  const updatedDependents = dependents.map(dependent => {
    let dependentInfo;
    const {
      firstName,
      lastName,
      consumerId,
      dateOfBirth,
      eligibilities,
      address1,
      zip,
      address2,
      city,
      state,
      cardNumber,
      subscriptionAccount,
      isLegacy,
      isPossibleTwin,
      hasSeenLegacyAgreement,
      email,
      personId
    } = dependent;
    if (subscriptionAccount) {
      const { accountNumber, id } = subscriptionAccount;
      dependentInfo = {
        ...dependentInfo,
        accountNumber,
        accountId: id,
        subscriptionAccount
      };
    }
    if (eligibilities) {
      dependentInfo = {
        ...dependentInfo,
        isFWEligible: hasEligibility(eligibilities, 'fw'),
        isFWEnrolled: checkEligibility(eligibilities, 'fw'),
        isEligibleForDefaultProgram: hasEligibility(eligibilities, programCode),
        isEnrolledInDefaultProgram: checkEligibility(
          eligibilities,
          programCode
        ),
        isStudioEligible: hasEligibility(eligibilities, 'studio'),
        isStudioEnrolled: checkEligibility(eligibilities, 'studio'),
        isEligibleForDigitalTier: hasEligibility(
          eligibilities,
          'prime_digital'
        ),
        isEnrolledInDigitalTier: checkEligibility(
          eligibilities,
          'prime_digital'
        )
      };
    }
    if (hasEligibility(eligibilities, programCode)) {
      const defaultProgramEligibility = eligibilities.find(
        eligibility => eligibility.programCode === programCode
      );
      const {
        currentSubscriptionPauseStartDate,
        currentSubscriptionPauseEndDate,
        currentSubscription,
        futureSubscription,
        memberTermDate,
        currentSubscriptionStatus,
        homeGyms,
        isPrimary,
        isDependentToMember,
        isMembershipActive
      } = defaultProgramEligibility;

      const homeGymData = getHomeGyms(client, homeGyms);

      let defaultProgram = {
        defaultProgramEligibility,
        programCode: programCode,
        isPrimary,
        isActive: futureSubscription
          ? futureSubscription.ratePlanChargeId !==
            currentSubscription?.ratePlanChargeId
            ? false
            : true
          : currentSubscriptionStatus == 'Active' &&
            memberTermDate === '9999-12-31T23:59:59',
        isSuspending: currentSubscriptionStatus == 'ActiveFutureSuspend',
        isSuspended: currentSubscriptionStatus == 'Suspended',
        isCancelled:
          currentSubscriptionStatus == 'Cancelled' &&
          memberTermDate !== '9999-12-31T23:59:00',
        isCancelling:
          currentSubscriptionStatus == 'ActiveFutureTerm' &&
          memberTermDate !== '9999-12-31T23:59:00',
        isTransitioning:
          memberTermDate === '9999-12-31T23:59:00' && futureSubscription
            ? futureSubscription.ratePlanChargeId !==
              currentSubscription?.ratePlanChargeId
              ? true
              : false
            : false,
        currentSubscription: futureSubscription
          ? futureSubscription.ratePlanChargeId !==
            currentSubscription?.ratePlanChargeId
            ? currentSubscription
            : futureSubscription
          : currentSubscription,
        futureSubscription: futureSubscription
          ? futureSubscription.ratePlanChargeId !==
            currentSubscription?.ratePlanChargeId
            ? futureSubscription
            : null
          : null,
        ...(homeGymData && {
          currentHomeGym: homeGymData.currentHomeGym,
          futureHomeGym: homeGymData.futureHomeGym
        }),
        tier: currentSubscription?.tier,
        ...(currentSubscriptionPauseStartDate && {
          currentSubscriptionPauseStartDate
        }),
        ...(currentSubscriptionPauseEndDate && {
          currentSubscriptionPauseEndDate
        }),
        memberTermDate,
        currentSubscriptionStatus,
        isMembershipActive,
        isDependentToMember
      };

      dependentInfo = {
        ...dependentInfo,
        isPrimary,
        isDependentToMember,
        canBeEnrolled:
          !isPrimary && !isMembershipActive && !isDependentToMember,
        defaultProgram
      };
    }
    if (hasEligibility(eligibilities, 'prime_digital')) {
      const primeDigitalEligibility = eligibilities.find(
        eligibility => eligibility.programCode === 'prime_digital'
      );
      const {
        memberTermDate,
        currentSubscriptionPauseStartDate,
        currentSubscriptionPauseEndDate,
        currentSubscriptionStatus,
        currentSubscription,
        isMembershipActive,
        isPrimary,
        isDependentToMember
      } = primeDigitalEligibility;

      let digitalProgram = {
        primeDigitalEligibility,
        programCode: 'prime_digital',
        isActive:
          currentSubscriptionStatus == 'Active' &&
          memberTermDate === '9999-12-31T23:59:59',
        isSuspending: currentSubscriptionStatus == 'ActiveFutureSuspend',
        isSuspended: currentSubscriptionStatus == 'Suspended',
        isCancelled:
          currentSubscriptionStatus == 'Cancelled' &&
          memberTermDate !== '9999-12-31T23:59:00',
        isCancelling:
          currentSubscriptionStatus == 'ActiveFutureTerm' &&
          memberTermDate !== '9999-12-31T23:59:00',
        ...(currentSubscriptionPauseStartDate && {
          currentSubscriptionPauseStartDate
        }),
        ...(currentSubscriptionPauseEndDate && {
          currentSubscriptionPauseEndDate
        }),
        memberTermDate,
        currentSubscriptionStatus,
        currentSubscription,
        isMembershipActive,
        isDependentToMember
      };

      dependentInfo = {
        ...dependentInfo,
        canBeEnrolled: !dependentInfo.canBeEnrolled
          ? (!dependentInfo.isPrimary ? true : isPrimary) &&
            !isMembershipActive &&
            (!dependentInfo.isDependentToMember ? true : isDependentToMember)
          : dependentInfo.canBeEnrolled,
        ...(dependentInfo.isPrimary ? {} : { isPrimary }),
        ...(dependentInfo.isDependentToMember ? {} : { isDependentToMember }),
        primeDigitalSubscription: primeDigitalEligibility,
        digitalProgram
      };
    }
    dependentInfo = {
      ...dependentInfo,
      firstName,
      lastName,
      consumerId,
      dateOfBirth,
      isUnderage: isUnderage(dependent.dateOfBirth),
      personId,
      eligibilities,
      address1,
      address2,
      city,
      email,
      state,
      cardNumber,
      isLegacy,
      isPossibleTwin,
      hasSeenLegacyAgreement,
      zip,
      shippingAddress: {
        address1,
        address2,
        city,
        zip,
        state
      }
    };
    return dependentInfo;
  });
  const availableDependents = updatedDependents.filter(
    dependent => dependent.canBeEnrolled
  );
  const enrolledDependents = updatedDependents.filter(
    dependent => dependent.isDependentToMember && !dependent.isPrimary
  );
  return {
    availableDependents: availableDependents,
    enrolledDependents: enrolledDependents.map(dependent => {
      dependent.canBeEnrolled = false;
      return dependent;
    })
  };
};

export const setUserInfo = async (programCode, client, userDispatch) => {
  try {
    let userInfo = {};
    const accountInfo = await axios.get('/api/prime/v2/account');
    if (accountInfo.data.data.member) {
      const {
        isImpersonated,
        isEligibilityCheckFailed,
        csrUsername,
        member,
        dependents
      } = accountInfo.data.data;
      const {
        firstName,
        lastName,
        consumerId,
        dateOfBirth,
        eligibilities,
        address1,
        zip,
        address2,
        city,
        state,
        cardNumber,
        subscriptionAccount,
        isLegacy,
        isPossibleTwin,
        hasSeenLegacyAgreement,
        email,
        personId
      } = member;

      if (dependents) {
        const dependentInfo = await getDependents(
          programCode,
          dependents,
          client
        );
        userInfo = {
          ...userInfo,
          dependents: dependentInfo.availableDependents,
          enrolledDependents: dependentInfo.enrolledDependents
        };
      }
      if (subscriptionAccount) {
        const {
          accountNumber,
          nextPaymentInfo,
          id,
          paymentMethods
        } = subscriptionAccount;
        userInfo = {
          ...userInfo,
          accountNumber,
          nextPaymentInfo,
          accountId: id,
          paymentMethods,
          subscriptionAccount
        };
      }
      if (eligibilities) {
        userInfo = {
          ...userInfo,
          isFWEligible: hasEligibility(eligibilities, 'fw'),
          isFWEnrolled: checkEligibility(eligibilities, 'fw'),
          isEligibleForDefaultProgram: hasEligibility(
            eligibilities,
            programCode
          ),
          isEnrolledInDefaultProgram: checkEligibility(
            eligibilities,
            programCode
          ),
          isStudioEligible: hasEligibility(eligibilities, 'studio'),
          isStudioEnrolled: checkEligibility(eligibilities, 'studio'),
          isEligibleForDigitalTier: hasEligibility(
            eligibilities,
            'prime_digital'
          ),
          isEnrolledInDigitalTier: checkEligibility(
            eligibilities,
            'prime_digital'
          )
        };
      }
      if (hasEligibility(eligibilities, programCode)) {
        const defaultProgramEligibility = eligibilities.find(
          eligibility => eligibility.programCode === programCode
        );
        const {
          currentSubscriptionPauseStartDate,
          currentSubscriptionPauseEndDate,
          currentSubscription,
          futureSubscription,
          memberTermDate,
          currentSubscriptionStatus,
          homeGyms,
          isPrimary,
          isMembershipActive
        } = defaultProgramEligibility;

        const homeGymData = await getHomeGyms(client, homeGyms);

        let defaultProgram = {
          defaultProgramEligibility,
          programCode: programCode,
          isPrimary,
          isActive: futureSubscription
            ? futureSubscription.ratePlanChargeId !==
              currentSubscription?.ratePlanChargeId
              ? false
              : true
            : currentSubscriptionStatus == 'Active' &&
              memberTermDate === '9999-12-31T23:59:59',
          isSuspending: currentSubscriptionStatus == 'ActiveFutureSuspend',
          isSuspended: currentSubscriptionStatus == 'Suspended',
          isCancelled:
            currentSubscriptionStatus == 'Cancelled' &&
            memberTermDate !== '9999-12-31T23:59:00',
          isCancelling:
            currentSubscriptionStatus == 'ActiveFutureTerm' &&
            memberTermDate !== '9999-12-31T23:59:00',
          isTransitioning:
            memberTermDate === '9999-12-31T23:59:00' && futureSubscription
              ? futureSubscription.ratePlanChargeId !==
                currentSubscription?.ratePlanChargeId
                ? true
                : false
              : false,
          currentSubscription: futureSubscription
            ? futureSubscription.ratePlanChargeId !==
              currentSubscription?.ratePlanChargeId
              ? currentSubscription
              : futureSubscription
            : currentSubscription,
          futureSubscription: futureSubscription
            ? futureSubscription.ratePlanChargeId !==
              currentSubscription?.ratePlanChargeId
              ? futureSubscription
              : null
            : null,
          ...(homeGymData && {
            currentHomeGym: homeGymData.currentHomeGym,
            futureHomeGym: homeGymData.futureHomeGym
          }),
          tier: currentSubscription?.tier,
          ...(currentSubscriptionPauseStartDate && {
            currentSubscriptionPauseStartDate
          }),
          ...(currentSubscriptionPauseEndDate && {
            currentSubscriptionPauseEndDate
          }),
          memberTermDate,
          currentSubscriptionStatus,
          isMembershipActive
        };

        userInfo = {
          ...userInfo,
          isPrimary,
          defaultProgram
        };
      }
      if (hasEligibility(eligibilities, 'prime_digital')) {
        const primeDigitalEligibility = eligibilities.find(
          eligibility => eligibility.programCode === 'prime_digital'
        );
        const {
          memberTermDate,
          currentSubscriptionPauseStartDate,
          currentSubscriptionPauseEndDate,
          currentSubscriptionStatus,
          currentSubscription,
          isPrimary,
          isMembershipActive
        } = primeDigitalEligibility;

        let digitalProgram = {
          primeDigitalEligibility,
          programCode: 'prime_digital',
          isActive:
            currentSubscriptionStatus == 'Active' &&
            memberTermDate === '9999-12-31T23:59:59',
          isSuspending: currentSubscriptionStatus == 'ActiveFutureSuspend',
          isSuspended: currentSubscriptionStatus == 'Suspended',
          isCancelled:
            currentSubscriptionStatus == 'Cancelled' &&
            memberTermDate !== '9999-12-31T23:59:00',
          isCancelling:
            currentSubscriptionStatus == 'ActiveFutureTerm' &&
            memberTermDate !== '9999-12-31T23:59:00',
          ...(currentSubscriptionPauseStartDate && {
            currentSubscriptionPauseStartDate
          }),
          ...(currentSubscriptionPauseEndDate && {
            currentSubscriptionPauseEndDate
          }),
          memberTermDate,
          currentSubscriptionStatus,
          currentSubscription,
          isMembershipActive
        };

        userInfo = {
          ...userInfo,
          ...(userInfo.isPrimary ? {} : { isPrimary }),
          primeDigitalSubscription: primeDigitalEligibility,
          digitalProgram
        };
      }
      userInfo = {
        ...userInfo,
        firstName,
        lastName,
        consumerId,
        dateOfBirth,
        personId,
        eligibilities,
        address1,
        address2,
        city,
        email,
        state,
        cardNumber,
        isLegacy,
        isPossibleTwin,
        hasSeenLegacyAgreement,
        isImpersonated,
        isEligibilityCheckFailed,
        zip,
        shippingAddress: {
          address1,
          address2,
          city,
          zip,
          state
        },
        csrUsername
      };
      userDispatch({
        type: SET_USER_INFO,
        userInfo
      });
    } else {
      throw Error;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getHomeGyms = async (client, homeGyms) => {
  //console.log('here ' + JSON.stringify(homeGyms));
  if (!homeGyms || homeGyms.length === 0) return null;
  try {
    const homeGymData = await mapHomeGyms(client, homeGyms);
    if (!homeGymData) throw new Error();

    const { currentHomeGym, futureHomeGym } = homeGymData;
    if (homeGymData) {
      return { currentHomeGym, futureHomeGym };
    } else {
      throw new Error();
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const mapHomeGyms = async (client, homeGyms) => {
  if (homeGyms.length === 0) return null;

  const homeGymData = await Promise.all(
    homeGyms.map(async gym => {
      const locationData = await getUsersHomeGym(client, gym.homeGymCode);
      return { ...locationData, ...gym };
    })
  );
  let currentHomeGym = null;
  let futureHomeGym = null;
  if (
    homeGymData.length === 1 &&
    !isPast(new Date(homeGymData[0].homeGymEndDate))
  ) {
    currentHomeGym = homeGymData[0];
  } else if (homeGymData.length > 1) {
    homeGymData.map(async gym => {
      if (gym.homeGymEndDate === '9999-12-31T00:00:00') {
        if (isPast(new Date(gym.homeGymStartDate))) {
          currentHomeGym = gym;
        } else {
          futureHomeGym = gym;
        }
      } else {
        if (isPast(new Date(gym.homeGymStartDate))) {
          if (isFuture(new Date(gym.homeGymEndDate))) {
            currentHomeGym = gym;
          }
        } else {
          if (isFuture(new Date(gym.homeGymEndDate))) {
            futureHomeGym = gym;
          }
        }
      }
    });
  }

  return { currentHomeGym, futureHomeGym };
};

const getUsersHomeGym = async (client, homeGymCode) => {
  if (!homeGymCode) return null;

  try {
    const homeGym = await axios.get(
      `/api/${client}/network/locations/${homeGymCode}`
    );
    const homeGymInfo = homeGym.data.data;
    return homeGymInfo;
  } catch (error) {
    return null;
  }
};

export const setMobileNavbarVisible = mobileNavbarVisible => ({
  type: SET_MOBILENAVBARVISIBLE,
  mobileNavbarVisible
});

export const logOut = () => ({
  type: LOG_OUT
});
